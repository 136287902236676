<template>
	<transition>
		<div>
			<DxDataGrid
				key-expr="id"
				ref="modalRecordList"
				:data-source="contentData"
				:allow-column-resizing="true"
				:no-data-text="this.$_msgContents('CMN_NO_DATA')"
				:show-borders="false"
				:show-column-lines="true"
				:show-row-lines="true"
			>
				<DxFilterRow :visible="false" />
				<DxSelection mode="single" />
				<DxLoadPanel :enabled="false" />

				<DxColumn data-field="recTypeCd" caption="콜 구분" :width="110" alignment="center" calculate-sort-value="calSortValue">
					<DxLookup :data-source="getEvalCallYype" value-expr="value" display-expr="label" />
				</DxColumn>
				<DxColumn data-field="callId" caption="CALLID" alignment="center" />
				<DxColumn data-field="recDate" caption="통화일" alignment="center" />
				<DxColumn data-field="recStartTime" caption="통화시작시간" alignment="center" cell-template="startTimeTemplate" />
				<DxColumn data-field="recEndTime" caption="통화종료시간" alignment="center" cell-template="endTimeTemplate" />
				<DxColumn data-field="recDuration" caption="통화시간" alignment="center" />
				<DxColumn data-field="listenCall" caption="청취" alignment="center" cell-template="callTemplate" />
				<template #startTimeTemplate="{ data }">
					{{ formatTime(data.row.data.recStartTime) }}
				</template>
				<template #endTimeTemplate="{ data }">
					{{ formatTime(data.row.data.recEndTime) }}
				</template>
				<template #callTemplate="{ data }">
					<DxButton
						class="white light_filled txt_S"
						styling-mode="contained"
						template='<span class="mdi mdi-volume-high"></span>'
						@click="onListenCall(data.row)"
					/>
				</template>
			</DxDataGrid>
		</div>
	</transition>
</template>

<script>
import { DxDataGrid, DxColumn, DxSelection, DxFilterRow, DxLoadPanel, DxLookup } from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';


export default {
	components: {
		DxDataGrid,
		DxColumn,
		DxSelection,
		DxFilterRow,
		DxLoadPanel,
		DxLookup,
		DxButton,
	},
	props: {
		contentData: Array,
	},
	watch: {},
	data() {
		return {};
	},
	computed: {},
	methods: {
		calSortValue(data) {
			this.$_enums.ewm.evalCallYype.values.find(e => data.recTypeCd == e.value).label;
		},
		/** @description : 청취 */
		onListenCall() {
			this.$_Confirm('선택한 우수콜을 들으시겠습니까?');
		},
		formatTime(timeString) {
			// console.log(timeString)
			const hours = timeString.substring(0, 2);
			const minutes = timeString.substring(2, 4);
			const seconds = timeString.substring(4, 6);

			const formattedTime = `${hours}:${minutes}:${seconds}`;

			return formattedTime;
		},
		getEvalCallYype() {
			return this.$_enums.ewm.evalCallYype.values;
		},
	},
	created() {},
	mounted() {},
};
</script>
